import React from 'react';

const GamePage: React.FC = () => {
  return (
    <div>
      <h1>Jig-Jag Jo Game</h1>
      <p>Game implementation goes here...</p>
    </div>
  );
};

export default GamePage;
